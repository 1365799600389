import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { useUpdateProjectKeyControl, useDeleteProjectKeyControl } from "@services/customForm/internalControls.js"
import { INTERNAL_CONTROLS } from '@constants/customForm'
import styles from './styles.module.css';
import { renderLabel } from '@ais/forms';
import { ReactComponent as DeleteIcon } from '@assets/delete_icon.svg';
import { IconButton, Box }from '@mui/material';
import { CLADialogConfirm } from '@ais/components';
import { ConcurrentCheckbox } from '../../../ConcurrentCheckbox'
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox'
import CustomToast from '@components/CustomToast/CustomToast';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext'; 
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import clsx from 'clsx';

import { useOthers } from '@components/Concurrency/store/users';
import { useRoomIdle, useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { WAITING_TIME } from '@ais/constants';
import { useProjectFormInstanceProvider } from '@providers';
const getWalkthroughFocusedId = projectKeyControlId => `key-control-${projectKeyControlId}-walkthrough-comment-textbox`;

const CustomKeyControl = ({ 
  projectScopeAuditAreaId, 
  projectScopeAuditAreaSCOTABDId, 
  projectKeyControls, 
  assertionList,
  isProjectFinalized,
  signOffList,
  userId
}) => {
  const { projectFormId } = useParams();
  const dialogConfigsInitialValue = { visibility: false, id: undefined }
  const { projectId } = useParams();
  const [deleteDialogConfigs, setDeleteDialogConfigs] = useState(dialogConfigsInitialValue);
  const [keyControlsData, setKeyControlsData] = useState([])
  const [loadingKeyControls, setLoadingKeyControls] = useState(false)
  const [errorKeyControls, setErrorKeyControls] = useState(false)
  const [successKeyControls, setSuccessKeyControls] = useState(false)
  const [assertionNames, setAssertionNames] = useState('')
  const { mutateAsync: updateProjectKeyControl } = useUpdateProjectKeyControl();
  const { mutateAsync: deleteProjectKeyControl } = useDeleteProjectKeyControl(projectId, projectFormId);
  const [keyControlHistory, setKeyControlHistory] = useState([])
  const [tableHighlighted, setTableHighlighted] = useState(false)
  const activeFieldRef = useRef(null)
  const [ focusedIndex, setFocusedIndex ] = useState(null)
  const [ previousValue, setPreviousValue ] = useState(null)
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const isIdle = useRoomIdle()
  const { formattedInternalControls, changeScotabdKeyControlField, changeInternalControlsObj } =
    useInternalControlsContext();
  
  const {
    TOOLTIPS,
    KEYS,
    LABELS,
    CUSTOM_KEY,
    MODAL,
    TEXT
  } = INTERNAL_CONTROLS.EN

  const uniqueLatestData = (data) => {
    const uniqueMap = new Map();
    
    data.forEach((item) => {
        if (uniqueMap.has(item.ProjectKeyControlId)) {
            const existingItem = uniqueMap.get(item.ProjectKeyControlId);
            if (new Date(item.ValidTo) > new Date(existingItem.ValidTo)) {
                uniqueMap.set(item.ProjectKeyControlId, item);
            }
        } else {
            uniqueMap.set(item.ProjectKeyControlId, item);
        }
    });
    
    return Array.from(uniqueMap.values());
};

  useEffect(() => {
    if (isIdle && focusedIndex !== null) {  
      setKeyControlsData(prev => {
        const _copy = [...prev]
        _copy[focusedIndex].ProjectKeyControl.WalkthroughComment = previousValue

        return _copy
      })
      activeFieldRef.current?.blur()  
    }
  }, [isIdle])

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    if (!projectKeyControls || projectKeyControls.length < 1) return
    const keyControlsData = projectKeyControls
      .map(({ ProjectKeyControl, IsCoversOtherRelativeAssertion }) => { 
        const dateModified = new Date(ProjectKeyControl[0].ValidFrom + 'Z').getTime();
        const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
        const shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
  
        return {
          ProjectKeyControl: ProjectKeyControl[0],
          IsCoversOtherRelativeAssertion, 
          isKeyControlHighlighted :  shouldHighlight && isSignoffEnabled
        }
      })
    const joinedAssertionNames = `${TEXT.OTHER_RELEVANT_ASSERTIONS} ${assertionList?.map(item => item.AssertionName).sort().join(', ')}`;
    setAssertionNames(joinedAssertionNames)
    setKeyControlsData(keyControlsData)
  }, [projectKeyControls, keyControlHistory, signOffList])

  useEffect(()=>{
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let history = []
    let deletedHistory = []
    if(formattedInternalControls?.DeletedProjectScopeAuditAreaKeyControl?.length){
      deletedHistory = formattedInternalControls?.DeletedProjectScopeAuditAreaKeyControl?.filter((dkc) => {
        return new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < new Date(dkc?.ValidTo).getTime() 
        && dkc.ProjectScopeAuditAreaSCOTABDId === projectScopeAuditAreaSCOTABDId
        && !projectKeyControls.find((kc) => kc.ProjectKeyControl[0].ProjectKeyControlId === dkc.ProjectKeyControlId)
        && isSignoffLogicEnabled(isProjectFinalized, new Date(dkc?.ValidTo))
      }) 
    }
    if(deletedHistory.length > 0){
      setTableHighlighted(true)
    }else{
      setTableHighlighted(false)
    }
    if(formattedInternalControls?.ProjectScopeAuditAreaScotabdKeyControlHistory?.length){
      history = formattedInternalControls?.ProjectScopeAuditAreaScotabdKeyControlHistory.filter((kc) => {
        return new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < new Date(kc?.ValidTo).getTime()
          && kc.ProjectScopeAuditAreaSCOTABDId === projectScopeAuditAreaSCOTABDId
      })
    }
    setKeyControlHistory(history)
  }, [formattedInternalControls, signOffList, projectKeyControls])

  const findIndices = (projectKeyControlId) => {
    const foundAuditAreaIndex = formattedInternalControls?.ProjectScopeAuditArea?.findIndex(
      (item) => item.ProjectScopeAuditAreaId === projectScopeAuditAreaId
    );
    
    const foundScotabdIndex = formattedInternalControls?.ProjectScopeAuditArea?.[
      foundAuditAreaIndex
    ].ProjectScopeAuditAreaSCOTABDS.findIndex((scotabd) => scotabd.ProjectScopeAuditAreaSCOTABDId === projectScopeAuditAreaSCOTABDId);

    const foundScotabdKeyControlIndex = formattedInternalControls?.ProjectScopeAuditArea?.[
      foundAuditAreaIndex
    ].ProjectScopeAuditAreaSCOTABDS?.[foundScotabdIndex].ProjectScopeAuditAreaSCOTABDProjectKeyControl?.findIndex(
      (keyControl) => keyControl.ProjectKeyControl?.[0].ProjectKeyControlId === projectKeyControlId
    );

    return [foundAuditAreaIndex, foundScotabdIndex, foundScotabdKeyControlIndex];
  };

  const handleChanges = async (projectKeyControlId, key, value, index, prevValue = null) => {
      setFocusedIndex(null)
      if (isIdle) { 
        activeFieldRef.current = null
        setPreviousValue(null)
        updateMyPresence({ focusedId: null, type: 'custom' })
        return
      }
      if (prevValue && (value === previousValue)) {
        updateMyPresence({ focusedId: null, type: 'custom' })
        setPreviousValue(null)
        return
      }
      setKeyControlsData(prev => {
        const __copy = [...prev]
        __copy[index][key] = value

        return __copy
      });

    const payload =
      key === KEYS.IS_IMPLEMENTED_EFFECTIVELY
        ? { IsImplementedEffectively: value }
        : key === KEYS.IS_DESIGNED_EFFECTIVELY
          ? { IsDesignedEffectively: value }
          : { WalkthroughComment: value };
    payload['RequestProjectFormId'] = projectFormId;

    if (key === KEYS.IS_IMPLEMENTED_EFFECTIVELY || key !== KEYS.IS_DESIGNED_EFFECTIVELY) {
      updateMyPresence({ focusedId: null, type: 'custom' })
    }

    const [auditAreaIndex, scotabdIndex, scotabdKeyControlIndex] = findIndices(projectKeyControlId);
    const internalControlDataBeforeUpdate = structuredClone(formattedInternalControls);
    changeScotabdKeyControlField(auditAreaIndex, scotabdIndex, scotabdKeyControlIndex, key, value);

    try {
      setLoadingKeyControls(true)
      await updateProjectKeyControl({
        ProjectKeyControlId: projectKeyControlId,
        ProjectId: projectId,
        payload,
        projectId
      })
      setSuccessKeyControls(true)
      setLoadingKeyControls(false)
      setPreviousValue(null)
    } catch (error) {
      changeInternalControlsObj(internalControlDataBeforeUpdate);
      setLoadingKeyControls(false)
      setSuccessKeyControls(false)
      setErrorKeyControls(true)
      setPreviousValue(null)
    }
  }

  const deleteHandler = async projectKeyControlId => {
    const res = await deleteProjectKeyControl({projectKeyControlId})
    
    /** Condition if the deleteProjectKeyControl() is success */
    if (res) {
      setDeleteDialogConfigs(dialogConfigsInitialValue);
    }
  }

  return (
    <React.Fragment>
      <div className={styles['custom-table-container']}>
        <table className={clsx(styles['custom-table'], tableHighlighted && styles['table-highlighted'])}>
          <thead>
            <th className={styles['custom-table-key-controls']}>
              <span>{renderLabel(LABELS.KEY_CONTROLS, TOOLTIPS.KEY_CONTROLS_HEADER, null, { fontSize: '17px', marginLeft: '5px', color: "#2e334e" })}</span>
            </th>
            <th className="risks">
              <span>{CUSTOM_KEY.RISKS}</span>
            </th>
            <th className={styles['custom-table-design-header']}><span>{CUSTOM_KEY.CONTROL_DESIGNED_EFFECTIVELY}</span></th>
            <th className={styles['custom-table-implementation-header']}><span>{CUSTOM_KEY.CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY}</span></th>
            <th></th>
          </thead>
          <tbody>
            {
              (keyControlsData || keyControlsData.length > 0)
              && keyControlsData.map(({ ProjectKeyControl, IsCoversOtherRelativeAssertion, isKeyControlHighlighted }, index) => 
                {
                  const riskNames = ProjectKeyControl?.ProjectKeyControlProjectRisk?.map(item => item.RiskName) ?? [];
                  if(!!IsCoversOtherRelativeAssertion) {
                    riskNames.push(assertionNames)
                  }

                  return (
                    <React.Fragment key={`sc-kc-${ProjectKeyControl.ProjectKeyControlId}`}>
                      <tr className={clsx(styles['custom-key-control-tr'], { [styles['row-highlighted']]: isKeyControlHighlighted })}>
                        <td>
                          <div className={styles['custom-table-key-control-content']}>
                            {ProjectKeyControl.ProjectKeyControlName}
                          </div>
                        </td>
                        <td>
                          <div className={styles['custom-table-risk-content']}>
                            {riskNames.join(', ')}
                          </div>
                        </td>
                        <td>
                          <div className={styles['custom-table-design-content']}>
                            <Box>
                              <ConcurrentCheckbox
                                onChange={(event) => {
                                  handleChanges(ProjectKeyControl.ProjectKeyControlId, KEYS.IS_DESIGNED_EFFECTIVELY, event.target.checked, index)
                                }}
                                checked={ProjectKeyControl.IsDesignedEffectively}
                                value={ProjectKeyControl.IsDesignedEffectively}
                                customFormObjectId={`custom-key-control-checkbox-${index}-is-designed-effectively`}
                                disabled={isProjectFinalized}
                              />
                            </Box>

                          </div>
                        </td>
                        <td>
                          <div className={styles['custom-table-implement-content']}>
                          <Box>
                            <ConcurrentCheckbox
                                onChange={(event) => {
                                  handleChanges(ProjectKeyControl.ProjectKeyControlId, KEYS.IS_IMPLEMENTED_EFFECTIVELY, event.target.checked, index)
                                }}
                                checked={ProjectKeyControl.IsImplementedEffectively}
                                value={ProjectKeyControl.IsImplementedEffectively}
                                customFormObjectId={`custom-key-control-checkbox-${index}-is-implemented-effectively`}
                                disabled={isProjectFinalized}
                              />
                            </Box>
                          </div>
                        </td>
                        <td>
                          <IconButton
                            onClick={() => {
                              setDeleteDialogConfigs({
                                visibility: true,
                                id: ProjectKeyControl.ProjectKeyControlId
                              })
                            }}
                            className={styles['custom-content-delete-icon']}
                            disabled={isProjectFinalized}
                          >
                            <DeleteIcon sx={{ fontSize: 40 }} />
                          </IconButton>
                        </td>
                      </tr>
                      <tr className={clsx(isKeyControlHighlighted && styles['row-highlighted-bottom'])}>
                        <td colspan="5">
                          <VFRenderedFieldWrapper
                            className={clsx(styles["field__wrapper"], { 
                              [styles["custom-content-text-field"]]: true,
                              [styles["has-border"]]: index < projectKeyControls.length - 1 
                            })}
                            isLockedByUser={others.find((user) => user.presence.focusedId === getWalkthroughFocusedId(ProjectKeyControl.ProjectKeyControlId) && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                          >
                            <ConcurrentInternalControlTextbox
                              className={styles['custom-content-text-field']}
                              label={renderLabel(LABELS.WALKTHROUGH_COMMENT, TOOLTIPS.TEXTFIELD, null, { fontSize: '23px', marginLeft: '10px', color: "#2e334e" })}
                              value={ProjectKeyControl.WalkthroughComment || ""}
                              inputLableShrink={true}
                              onBlur={event => handleChanges(ProjectKeyControl.ProjectKeyControlId, KEYS.WALKTHROUGH_COMMENT, event.target.value, index, ProjectKeyControl.WalkthroughComment)}
                              onFocus={(event) => {
                                updateMyPresence({ focusedId: getWalkthroughFocusedId(ProjectKeyControl.ProjectKeyControlId), type: 'custom' })
                                setFocusedIndex(index)
                                setPreviousValue(ProjectKeyControl.WalkthroughComment)
                                activeFieldRef.current = event.target
                              }}
                              onKeyUp={event => setKeyControlsData(prev => {
                                const _copy = [ ...prev ]
                                _copy[index].ProjectKeyControl.WalkthroughComment = event.target.value

                                return _copy
                              })}
                              sx={{
                                width: '100%',
                              }}
                              inputProps={{
                                style: { fontSize: 14 }
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              customFormObjectId={getWalkthroughFocusedId(ProjectKeyControl.ProjectKeyControlId)}
                              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === getWalkthroughFocusedId(ProjectKeyControl.ProjectKeyControlId) && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                              maxLength={null}
                            />
                          </VFRenderedFieldWrapper>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                }
              )
            }
          </tbody>
        </table>
      </div>
      <CLADialogConfirm
        visible={deleteDialogConfigs.visibility}
        title={MODAL.TITLE}
        cancelText={MODAL.CANCEL}
        confirmText={MODAL.DELETE}
        message={MODAL.BODY}
        onConfirm={() => deleteHandler(deleteDialogConfigs.id)}
        onCancel={() => setDeleteDialogConfigs(dialogConfigsInitialValue)}
      />
      <CustomToast 
        error={errorKeyControls} 
        success={successKeyControls}
        loading={loadingKeyControls}
      />
    </React.Fragment>
  )
}

export default CustomKeyControl