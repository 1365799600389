import { useContext } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RESERVED_SECTION_IDS } from '@ais/constants';

import { RiskAssessmentContext } from "@contexts/RiskAssessment/RiskAssessmentContext";
import { useLoading } from '@hooks/index';
import { getProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk.js";
import { fetchProjectRisksAuditArea } from "@services/riskassessment";
import formServices from '@services/forms/forms';

export const useRiskAssessment = () => {
  const { RISK_SUMMARY: {
    FINANCIAL_STATEMENT_LEVEL_RISK,
    ASSERTION_LEVEL_RISK,
  }} = RESERVED_SECTION_IDS;

  const {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    financialStatementPersuasiveRisk,
    setFinancialStatementPersuasiveRisk,
    auditAreaWithComment,
    setAuditAreaWithComment,
    concurrencyRiskInfo,
    setConcurrencyRiskInfo,
    concurrencyOtherRelevantAssertion,
    setConcurrencyOtherRelevantAssertion,
    isFinancialStatementExpanded,
    isAssertionLevelRiskExpanded,
    auditAreaScotabdRisk,
    modifiedRiskAssessmentSummaryAfterSignOff,
    signOffList,
    financialStatementLevelRiskToggle,
    loadingProjectRiskAssessmentSummary,
    successProjectRiskAssessmentSummary,
    errorProjectRiskAssessmentSummary,
    actions
  } = useContext(RiskAssessmentContext);

  const { projectId, projectFormId } = useParams();
  const setLoading = useLoading();

  const fetchFinancialStatementLevelData = async () => {
    try {
      setLoading(true);
      const result = await getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
      const projectRisks = result.data ?? [];
      const persuasiveRisks = projectRisks.filter((projectrisk) => projectrisk.IsPervasive === true)
      setFinancialStatementPersuasiveRisk(persuasiveRisks.map((persuasiveProjectRisk) => ({
        ProjectRiskId: persuasiveProjectRisk.ProjectRiskId,
        identifiedRisk: persuasiveProjectRisk.RiskName,
        NatureAndExtentOfPervasiveEffect: persuasiveProjectRisk.NatureAndExtentOfPervasiveEffect,
        PlannedOverallResponse: persuasiveProjectRisk.PlannedOverallResponse
      })));
      setLoading(false);
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    }
  }

  const updateFinancialStatementLevelPersuasiveRisk = async (risksummary = []) => {
    if (!risksummary.length) return;

    const result = await getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
    const projectRisks = result.data ?? [];
    const persuasiveRisks = projectRisks.filter((projectrisk) => projectrisk.IsPervasive === true)

    const financialPersuasiveRisks = [];
    persuasiveRisks.forEach(persuasiveRisk => {
      if (risksummary.find(risk => risk.ProjectRiskId === persuasiveRisk.ProjectRiskId)) {
        persuasiveRisk.NatureAndExtentOfPervasiveEffect = risksummary.find(risk => risk.ProjectRiskId === persuasiveRisk.ProjectRiskId).NatureAndExtentOfPervasiveEffect;
        persuasiveRisk.PlannedOverallResponse = risksummary.find(risk => risk.ProjectRiskId === persuasiveRisk.ProjectRiskId).PlannedOverallResponse
      }

      financialPersuasiveRisks.push(persuasiveRisk);
    })

    setFinancialStatementPersuasiveRisk(financialPersuasiveRisks);
  }

  const updateAssertionComment = async (auditAreaComment) => {
    const auditAreaResult = await fetchProjectRisksAuditArea(projectFormId, projectId);

    const auditAreaComments = [];
    auditAreaResult.forEach(auditComment => {
      let _auditAreaComment = ""
      if (auditAreaComment.AuditAreaId === auditComment.auditAreaId) {
        _auditAreaComment =
          (auditAreaComment.AuditAreaId === auditComment.auditAreaId && auditAreaComment.projectScopeAuditAreaId === auditComment.projectScopeAuditAreaId)
          && auditAreaComment.RiskAssessmentComment; 
        auditComment.riskAssessmentComment = !_auditAreaComment ? auditComment.riskAssessmentComment : _auditAreaComment
      } 
      auditAreaComments.push(auditComment);
    })
    setAuditAreaWithComment(auditAreaComments);
  }

  const updateAuditAreaRisk = async (answer = {}) => {
    const { riskInfo, projectScopeAuditAreaSCOTABDId } = answer.reqBody ?? {};
    const _projectScopeAuditAreaSCOTABDId = riskInfo ? riskInfo.projectScopeAuditAreaSCOTABDId : projectScopeAuditAreaSCOTABDId;
    if (_projectScopeAuditAreaSCOTABDId) {
      const projectRiskList = await formServices.getProjectScopeAuditAreaSCOTABDRiskList(_projectScopeAuditAreaSCOTABDId, projectId);
      setConcurrencyRiskInfo(projectRiskList);
    }
  }

  const updateOtherRelevantAssertion = async (projectScopeAuditAreaSCOTABDId = null, answer = {}) => {
    const { otherRelevantAssertion } = answer ?? {};
    if (otherRelevantAssertion) {
      const otherAssertion = await formServices.getProjectScopeAuditAreaSCOTABDOtherRelevantAssertions(projectScopeAuditAreaSCOTABDId, projectId);
      setConcurrencyOtherRelevantAssertion(otherAssertion)
    }
  }

  const updateNonCustomFieldAnswer = (questionId, answer) => {
    setAnswerList((prev) => ({ ...prev, [questionId]: answer }));
  }

  const updateRiskSectionExpanded = (sectionId, isExpanded) => {
    switch(sectionId) {
      case FINANCIAL_STATEMENT_LEVEL_RISK:
        actions.updateIsFinancialStatementExpanded(isExpanded);
        return;
      case ASSERTION_LEVEL_RISK:
        actions.updateIsAssertionLevelRiskExpanded(isExpanded);
        return;
      default:
        return;
    }
  };

  return {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    updateNonCustomFieldAnswer,
    updateFinancialStatementLevelPersuasiveRisk,
    updateAssertionComment,
    updateAuditAreaRisk,
    updateOtherRelevantAssertion,
    financialStatementPersuasiveRisk,
    setFinancialStatementPersuasiveRisk,
    auditAreaWithComment,
    setAuditAreaWithComment,
    concurrencyRiskInfo,
    setConcurrencyRiskInfo,
    concurrencyOtherRelevantAssertion,
    setConcurrencyOtherRelevantAssertion,
    fetchFinancialStatementLevelData,
    isFinancialStatementExpanded,
    isAssertionLevelRiskExpanded,
    auditAreaScotabdRisk,
    modifiedRiskAssessmentSummaryAfterSignOff,
    signOffList,
    financialStatementLevelRiskToggle,
    loadingProjectRiskAssessmentSummary,
    successProjectRiskAssessmentSummary,
    errorProjectRiskAssessmentSummary,
    actions: {...actions, updateRiskSectionExpanded}
  }
}