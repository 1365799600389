import { createContext, useState, useEffect, useRef } from 'react';
import { useFormContext } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { QUESTION_LINK_TYPE } from "@ais/constants"
import { isAnswerQuestionGroup, isAnswerTable, transformAnswers, transformAnswersToPayload } from '@ais/utilities';

import { useSaveProjectUnitAnswer } from '@services/forms/projectforms.js';
import projectFormServices, { useGetQuestionsWithLink } from "@services/forms/projectforms";

import CustomToast from '@components/FormView/v2/SaveIndicator/CustomToast';
import logger from '@utilities/logService'

export const ProjectFormInstanceContextV2 = createContext({});

export const ProjectFormInstanceProviderV2 = ({ projectData, projectFormData, answers, userId, children }) => {
    const [project] = useState(projectData);
    const [projectForm] = useState(projectFormData);
    const [schema, setSchema] = useState(projectForm.schema);
    const [answerList, setAnswerList] = useState(transformAnswers(answers ?? []));
    const [isCustomFormError, setIsCustomFormError] = useState(false);
    const [isCustomFormSuccess, setIsCustomFormSuccess] = useState(false);
    const [isCustomFormLoading, setIsCustomFormLoading] = useState(false);
    const [isQuestionGroupIdle, setIsQuestionGroupIdle] = useState(false);
    const { getValues, reset, setValue } = useFormContext();
    const { units } = projectForm;
    const { projectId, projectFormId } = useParams();
    const { data: questionsWithLink } = useGetQuestionsWithLink(projectId, projectFormId)
    const [relatedFields, setRelatedFields] = useState([]);
    const [focusedId, setFocusedId] = useState(null)
    const { mutateAsync: saveProjectUnitAnswer, isLoading, isError, isSuccess } = useSaveProjectUnitAnswer();
    const [linkDefaultFields, setLinkDefaultFields] = useState([])
    const [isSectionExpanded, setIsSectionExpanded] = useState([]);
    const fieldRef = useRef(null);
    const { QUESTION_LINK_TYPE: {
        DUPLICATE_QUESTION,
        LINK_ANSWER,
        LINK_ANSWER_DEFAULT
    } } = QUESTION_LINK_TYPE;

    const updateQuestionsWithLink = (id, answer) => {
        const { schema } = projectForm

        const result = []

        schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER) {
                        handleSubmit(field.existingQuestionId, answer)
                        return
                    }
                })
            )
        );

        schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER_DEFAULT) setLinkDefaultFields(prev => [...prev, id])
                    if (field.existingQuestionId && field.existingQuestionId === id && field.linkType !== DUPLICATE_QUESTION && !field.isUserModified) result.push(field.id)
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER) result.push(field.existingQuestionId)
                })
            )
        );
        if (result.length > 0) {
            result.forEach(id => {
                if (!linkDefaultFields.includes(id)) {
                    setValue(id, answer)
                    setAnswerList(prev => {
                        return { ...prev, [id]: answer }
                    })
                }
            })
        }
    }

    const handleSubmit = async (questionId) => {
        const isQuestionGroup = isAnswerQuestionGroup(questionId);
        if (isQuestionGroup) questionId = questionId.split('.')[0]
        const answer = getValues(questionId);
        const isTable = isAnswerTable(answer);
        if (answer === answerList[questionId]) return;

        const transformedValue = transformAnswersToPayload(answer, isTable, isQuestionGroup);
        if (isQuestionGroup || isTable)
            if (JSON.stringify(answer.map(ans => {
                const obj = {}
                for (const key in ans) {
                    if (ans[key] !== null && ans[key] !== undefined) obj[key] = ans[key]
                }
                return obj
            })) === JSON.stringify(answerList[questionId])) return
        try {
            const unitAnswer = {
                projectId,
                projectUnitIds: units.map(item => item.ProjectUnitID),
                questionId,
                answer: transformedValue
            };
            await saveProjectUnitAnswer({
                projectId,
                projectFormId,
                unitAnswer
            });

            updateQuestionsWithLink(questionId, answer)

            setAnswerList(prev => {
                return { ...prev, [questionId]: answer }
            })
        } catch (error) {
            logger.error(error.response.data.message ?? error.message);
            //Toast handles seperately via property state awareness of mutation Object
        }
    }

    const handleFocusField = (e, id) => {
        if (e) fieldRef.current = e.target;
        setFocusedId(id);
    }

    useEffect(() => {
        if (Object.keys(answerList).length > 0) {
            reset(answerList);
        }
    }, [answerList])

    useEffect(() => {
        setAnswerList(transformAnswers(answers ?? []));
        // link answer default if not modifed retrieve default or parent field
        projectForm.schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.linkType === LINK_ANSWER_DEFAULT && !field.isUserModified) {
                        const defaultAnswer = answers?.find(ans => ans.questionId?.toLowerCase() === field.existingQuestionId?.toLowerCase())
                        if (defaultAnswer) {
                            setValue(field.id, defaultAnswer.answer)
                            setAnswerList(prev => {
                                return { ...prev, [field.id]: defaultAnswer.answer }
                            })
                        }
                    }
                })
            )
        );
    }, [answers]);

    const handleLinkClick = async (questionId) => {
        if (questionId) {
            const { data } = await projectFormServices.getRelatedFieldsByQuestionsIds(projectId, questionId)
            if (data) {
                const relatedFields = data.map(item => ({
                    formId: item.projectFormId,
                    formName: item.projectFormName,
                    questionIds: item.questionIds,
                    sectionId: item.sectionId,
                    sectionName: item.sectionName,
                }))
                setRelatedFields(relatedFields)
            }
        } else {
            setRelatedFields([])
        }
    }

    const handleCustomFormSavingState = (type, value) => {
        if (type === 'isLoading') setIsCustomFormLoading(value);
        if (type === 'isSuccess') setIsCustomFormSuccess(value);
        if (type === 'isError') setIsCustomFormError(value);
    };

    const handleIsSectionExpanded = (section, isExpanded) => {
        setIsSectionExpanded(prevState => {
          const i = prevState.findIndex(item => item.section === section)
          if(i !== -1){
            return [
              ...prevState.slice(0,i),
              { ...prevState[i], isExpanded },
              ...prevState.slice(i+1)
            ]
          }
          else return [...prevState, { section, isExpanded }]
        })
      }
    return (
        <ProjectFormInstanceContextV2.Provider
            value={{
                project,
                projectForm,
                answers,
                answerList,
                questionsWithLink,
                relatedFields,
                focusedId,
                fieldRef,
                userId,
                schema,
                units,
                isSectionExpanded,
                isQuestionGroupIdle,
                actions: {
                    onSubmit: handleSubmit,
                    onLinkClick: handleLinkClick,
                    onFocus: handleFocusField,
                    onCustomFormSaving: handleCustomFormSavingState,
                    onUpdateEvent: setAnswerList,
                    updateSchema: setSchema,
                    handleIsSectionExpanded,
                    updateQuestionGroupIdle: setIsQuestionGroupIdle
                }
            }}
        >
            {children}
            <CustomToast
                error={isError || isCustomFormError}
                success={isSuccess || isCustomFormSuccess}
                loading={isLoading || isCustomFormLoading}
            />
        </ProjectFormInstanceContextV2.Provider>
    )
}
