import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField
} from '@mui/material';
import { sanitize } from 'dompurify';
import {CLADropdown} from "@ais/forms";
import { CLATooltip } from "@ais/components"
import styles from './CLAAccountAssertionLevelRisk.module.css';
import {
    boxColumnStyles,
    wrapCellStyles,
    cellStyles,
    boxHeaderRowRiskStyles,
    boxHeaderColStyles,
    boxRiskContainer,
    txtReferenceStyles,
    selectStyles
} from './muiStyles';

import formServices from '@services/forms/forms';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import CustomToast from '@components/CustomToast/CustomToast';

import { WAITING_TIME } from '@ais/constants';

import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from '@components/Concurrency/store/users';
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper"
import { useRiskAssessment } from '../../hooks/useRiskAssessment';

const CLAOtherRelevantAssertions = (props) => {
    const { projectId, projectFormId } = useParams();
    const {
        ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT,
        RISK_LEVEL_INHERIT_RISK,
        RISK_LEVEL_CONTROL_RISK,
        RISK_MATERIAL_MISSTATEMENT_MATRIX: MATRIX,
        RISKS_KEYVALUE_INHERENT,
        RISKS_KEYVALUE_CONTROL,
        RISKS_KEYVALUE_RMM
    } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const {
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        projectAuditAreaOtherRelevant,
        scotabd,
        disabled,
        isExpanded,
        userId
    } = props;

    const others = useOthers();
    const updateMyPresence = useUpdateMyPresence()
    const isIdle = useRoomIdle()
    const activeElementRef = useRef(null)

    const { otherRelevantAssertion, riskFactorInfo } = projectAuditAreaOtherRelevant;
    const [inherentRisk, setInherentRisk] = useState({
        inherentRiskAssessmentId: otherRelevantAssertion.inherentRiskAssessmentId,
        inherentRiskAssessmentName: otherRelevantAssertion.inherentRiskAssessmentName
    });
    const [controlRisk, setControlRisk] = useState({
        controlRiskAssessmentId: !isInternalControlEffectiveness
            ? 1
            : otherRelevantAssertion.controlRiskAssessmentId,
        controlRiskAssessmentName: !isInternalControlEffectiveness
            ? 'High'
            : otherRelevantAssertion.controlRiskAssessmentName,
    });
    const [rmm, setRmm] = useState(otherRelevantAssertion?.riskOfMaterialMisstatementName);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [successOtherRelevantAssertionAuditAreaSCOTABD, setSuccessOtherRelevantAssertionAuditAreaSCOTABD] = useState(false)
    const [isRiskChanged, setIsRiskChanged] = useState(false)
    const [shouldHighlight, setShouldHighlight] = useState();
    const [referenceWalkThrough, setReferenceWalkThrough] = useState(null);
    const [referenceForTestOfControl, setReferenceForTestOfControl] = useState(null);

    const { 
        concurrencyOtherRelevantAssertion, 
        concurrencyEventReceived,

        modifiedRiskAssessmentSummaryAfterSignOff,
        auditAreaScotabdRisk,
        actions: {
            riskAssessmentIdentifyModifiedAnswerAfterSignOff
        }
    } = useRiskAssessment();

    const applyClass = () => {
        let classList = [];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
        return classList.join(' ');
    };

    const joinEventConditionList = (condition) => Array.isArray(condition) ? condition?.map(e => e.eventConditionName).join(', ') : '';
    const riskFactorValue = () => riskFactorInfo?.map((r, i) =>
        <CLATooltip title={
            <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: sanitize(`<p><b>${r.riskFactorName}</b></p><p>${joinEventConditionList(r.eventCondition)}</p>`, { ADD_ATTR: ['target'] }) }}
            />
        } placement="bottom">
            <p key={i} className={styles['wrap-text']}>
                {r.riskFactorName}<br />
                {joinEventConditionList(r.eventCondition)}
            </p>
        </CLATooltip>);

    const handleRiskDropdownChanges = async (materialMisstatementRisk) => {
        const inherentRiskId = RISKS_KEYVALUE_INHERENT.find((risk) => risk.key === inherentRisk.inherentRiskAssessmentName)?.value;
        const controlRiskId = RISKS_KEYVALUE_CONTROL.find((risk) => risk.key === controlRisk.controlRiskAssessmentName)?.value;
        const materialMisstatementRiskId = RISKS_KEYVALUE_RMM.find((risk) => risk.key === materialMisstatementRisk)?.value;

        const requestBody = {
            RiskAssessmentReferenceForWalkThrough: referenceWalkThrough ?? otherRelevantAssertion.referenceForWalkThrough,
            RiskAssessmentInherentRiskAssessmentId: inherentRiskId,
            RiskAssessmentInherentRiskAssessmentName: inherentRisk.inherentRiskAssessmentName,
            RiskAssessmentReferenceForTestOfControl: referenceForTestOfControl ?? otherRelevantAssertion.referenceForTestOfControl,
            RiskAssessmentControlRiskAssessmentId: controlRiskId,
            RiskAssessmentControlRiskAssessmentName: controlRisk.controlRiskAssessmentName,
            RiskAssessmentRiskOfMaterialMisstatementId: materialMisstatementRiskId,
            RequestProjectFormId: projectFormId,
            otherRelevantAssertionInfo: otherRelevantAssertion
        }
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            setLoading(true)
            await formServices.updateOtherRelevantAssertionAuditAreaSCOTABD(projectId, otherRelevantAssertion.projectScopeAuditAreaSCOTABDId, requestBody);
            setLoading(false)
            setSuccessOtherRelevantAssertionAuditAreaSCOTABD(true)
        } catch (error) {
            setError(true)
            setLoading(false)
            throw error
        }
        setIsRiskChanged(false)
    };

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.projectScopeAuditAreaSCOTABDId === otherRelevantAssertion.projectScopeAuditAreaSCOTABDId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    useEffect(() => {
        if(isRiskChanged) riskFunc();
    }, [inherentRisk.inherentRiskAssessmentName, controlRisk.controlRiskAssessmentName, isInternalControlEffectiveness]);

    const riskFunc = () => {
        if (inherentRisk.inherentRiskAssessmentName && controlRisk.controlRiskAssessmentName) {
            const inherentRiskIndex = RISK_LEVEL_INHERIT_RISK.indexOf(inherentRisk.inherentRiskAssessmentName);
            const controlRiskIndex = RISK_LEVEL_CONTROL_RISK.indexOf(controlRisk.controlRiskAssessmentName);
            otherRelevantAssertion.riskOfMaterialMisstatementName = MATRIX[inherentRiskIndex][controlRiskIndex];

            setRmm(MATRIX[inherentRiskIndex][controlRiskIndex]);
        }
        handleRiskDropdownChanges(otherRelevantAssertion.riskOfMaterialMisstatementName);
    }

    const handleBlur = async (fieldValue, property) => {
        setLoading(true)
        const materialMisstatementRiskId = otherRelevantAssertion.riskOfMaterialMisstatementName ?
            RISKS_KEYVALUE_RMM.find((risk) => risk.key === otherRelevantAssertion?.riskOfMaterialMisstatementName)?.value : null;

        const existingRow = {
            RiskAssessmentReferenceForWalkThrough: referenceWalkThrough ?? otherRelevantAssertion.referenceForWalkThrough,
            RiskAssessmentInherentRiskAssessmentId: inherentRisk.inherentRiskAssessmentId ?? otherRelevantAssertion.inherentRiskAssessmentId,
            RiskAssessmentInherentRiskAssessmentName: inherentRisk.inherentRiskAssessmentName ?? otherRelevantAssertion.inherentRiskAssessmentName,
            RiskAssessmentReferenceForTestOfControl: referenceForTestOfControl ?? otherRelevantAssertion.referenceForTestOfControl,
            RiskAssessmentControlRiskAssessmentId: controlRisk.controlRiskAssessmentId ?? otherRelevantAssertion.controlRiskAssessmentId,
            RiskAssessmentControlRiskAssessmentName: controlRisk.controlRiskAssessmentName ?? otherRelevantAssertion.controlRiskAssessmentName,
            RiskAssessmentRiskOfMaterialMisstatementId: materialMisstatementRiskId,
        }

        const requestBody = {
            ...existingRow,
            [property]: fieldValue,
            RequestProjectFormId: projectFormId,
            otherRelevantAssertionInfo: otherRelevantAssertion
        };
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            await formServices.updateOtherRelevantAssertionAuditAreaSCOTABD(projectId, otherRelevantAssertion.projectScopeAuditAreaSCOTABDId, requestBody);
            setLoading(false)
            setSuccessOtherRelevantAssertionAuditAreaSCOTABD(true)
        } catch (error) {
            setError(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!concurrencyOtherRelevantAssertion || !concurrencyEventReceived) return;
        const { otherRelevantAssertion: otherRelevant } = concurrencyOtherRelevantAssertion ?? {};
        if (!!Object.entries(otherRelevant).length && otherRelevant.projectScopeAuditAreaSCOTABDId === otherRelevantAssertion.projectScopeAuditAreaSCOTABDId) {
            setReferenceWalkThrough(otherRelevant.referenceForWalkThrough)
            setReferenceForTestOfControl(otherRelevant.referenceForTestOfControl);
            setInherentRisk({
                inherentRiskAssessmentId: otherRelevant.inherentRiskAssessmentId,
                inherentRiskAssessmentName: otherRelevant.inherentRiskAssessmentName ?? otherRelevantAssertion.inherentRiskAssessmentName
            });
            setControlRisk({
                controlRiskAssessmentId: otherRelevant.controlRiskAssessmentId,
                controlRiskAssessmentName: otherRelevant.controlRiskAssessmentName ?? otherRelevantAssertion.controlRiskAssessmentName
            });
        }
    }, [concurrencyOtherRelevantAssertion])

    useEffect(() => {
        if (isIdle) {
            activeElementRef.current?.blur();
        }
      }, [isIdle])
    return (
        <Box sx={{ ...boxColumnStyles, ...boxRiskContainer }}>
            <CustomToast 
                success={successOtherRelevantAssertionAuditAreaSCOTABD} 
                error={error} 
                loading={loading}
            />
            <TableContainer >
                <Table data-test="tableContainer">
                    <TableHead data-test="tableHeader" sx={boxHeaderRowRiskStyles}>
                        <TableRow>
                            <TableCell sx={boxHeaderColStyles} data-test="other-relevant-header">{CONSTANT_TEXT.OTHER_RELEVANT_ASSERTIONS}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="inherent-risk-factor-header">{CONSTANT_TEXT.INHERENT_RISK_FACTOR}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="reference-walkthrough-header">{CONSTANT_TEXT.REFERENCE_FOR_WALKTHROUGH}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="inherent-risk-assessment-header">{CONSTANT_TEXT.INHERENT_RISK_ASSESSMENT}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="test-controls-header">{CONSTANT_TEXT.REFERENCE_FOR_TEST_OF_CONTROLS_IF_PERFORMED}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="control-risk-assessment-header">{CONSTANT_TEXT.CONTROL_RISK_ASSESSMENT}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="risk-material-header">{CONSTANT_TEXT.RISK_OF_MATERIAL_MISSTATEMENT}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-test="tableBody">
                        <TableRow data-test="tableRowBody" className={applyClass()}>
                            <TableCell title={Array.isArray(otherRelevantAssertion?.assertionList) && otherRelevantAssertion?.assertionList?.map(a => a.assertionName).join(', ')} sx={{ ...cellStyles, ...wrapCellStyles }}>{otherRelevantAssertion?.assertionList?.map(a => a.assertionName).join(', ')}</TableCell>
                            <TableCell sx={{ ...cellStyles, ...wrapCellStyles }}>
                                {riskFactorValue()}
                            </TableCell>
                            <TableCell sx={cellStyles}>
                                <VFRenderedFieldWrapper
                                    className={styles.field__wrapper}
                                    isLockedByUser={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-ReferenceForWalkThrough-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase()))}
                                    isLocked={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-ReferenceForWalkThrough-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())) && isExpanded}
                                >
                                    <TextField
                                        sx={txtReferenceStyles}
                                        placeholder={CONSTANT_TEXT.ENTER_REFERENCE_WALKTHROUGH}
                                        inputProps={{ maxLength: 256 }}
                                        defaultValue={otherRelevantAssertion?.referenceForWalkThrough ?? ''}
                                        value={referenceWalkThrough}
                                        onBlur={(e) => {
                                            if(!isIdle){
                                                const fieldValue = e?.target?.value;
                                                handleBlur(fieldValue, 'RiskAssessmentReferenceForWalkThrough');
                                            }else setReferenceWalkThrough(otherRelevantAssertion?.referenceForWalkThrough)
                                            updateMyPresence({ focusedId: null, type: 'custom' })
                                            activeElementRef.current = null
                                        }}
                                        onChange={(e) => {
                                            setReferenceWalkThrough(e.target.value)
                                        }}
                                        onFocus={(event) => {
                                            updateMyPresence({ focusedId: `OtherRelevantAssertion-ReferenceForWalkThrough-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}`, type: 'custom' })
                                            activeElementRef.current = event.target
                                        }}
                                        disabled={disabled}
                                    />
                                </VFRenderedFieldWrapper>
                            </TableCell>
                            <TableCell sx={{ ...cellStyles, ...selectStyles }}>
                                <VFRenderedFieldWrapper
                                    className={styles.field__wrapper}
                                    isLockedByUser={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-InherentRisk-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase()))}
                                    isLocked={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-InherentRisk-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())) && isExpanded}
                                >
                                    <CLADropdown
                                        options={inherentRiskOptions?.map(c => c.inherentRiskAssessmentName)}
                                        value={inherentRisk.inherentRiskAssessmentName ?? ''}
                                        onChange={(e) => {
                                            setIsRiskChanged(true)
                                            const inherit = inherentRiskOptions?.find(c => c.inherentRiskAssessmentName === e.target.value);
                                            if (inherit) {
                                                setInherentRisk({ inherentRiskAssessmentId: inherit.inherentRiskAssessmentId, inherentRiskAssessmentName: inherit.inherentRiskAssessmentName });
                                                if (!isInternalControlEffectiveness) {
                                                    otherRelevantAssertion.riskOfMaterialMisstatementName = inherit.inherentRiskAssessmentName;
                                                }
                                                otherRelevantAssertion.inherentRiskAssessmentId = inherit.inherentRiskAssessmentId;
                                                otherRelevantAssertion.inherentRiskAssessmentName = inherit.inherentRiskAssessmentName
                                            }
                                        }}
                                        onOpen={() => {
                                            updateMyPresence({ focusedId: `OtherRelevantAssertion-InherentRisk-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}`, type: 'custom' })
                                        }}
                                        onClose={(e) => {
                                            updateMyPresence({ focusedId: null, type: 'custom' })
                                        }}
                                        onClosedByIdle={()=>{
                                            updateMyPresence({ focusedId: null, type: 'custom' })
                                        }}
                                        isDisabled={disabled}
                                        isIdle={isIdle}
                                    />
                                </VFRenderedFieldWrapper>
                            </TableCell>
                            <TableCell sx={{ ...cellStyles, ...wrapCellStyles }} >
                                {isInternalControlEffectiveness &&
                                    <VFRenderedFieldWrapper
                                        className={styles.field__wrapper}
                                        isLockedByUser={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-ReferenceForTestOfControl-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase()))}
                                        isLocked={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-ReferenceForTestOfControl-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())) && isExpanded}
                                    >
                                        <TextField
                                            sx={txtReferenceStyles}
                                            placeholder={CONSTANT_TEXT.ENTER_REFERENCE_TEST}
                                            inputProps={{ maxLength: 256 }}
                                            defaultValue={otherRelevantAssertion?.referenceForTestOfControl ?? ''}
                                            value={referenceForTestOfControl}
                                            onBlur={(e) => {
                                                if(!isIdle){
                                                    const fieldValue = e?.target?.value;
                                                    handleBlur(fieldValue, 'RiskAssessmentReferenceForTestOfControl');
                                                }else setReferenceForTestOfControl(otherRelevantAssertion?.referenceForTestOfControl)
                                                updateMyPresence({ focusedId: null, type: 'custom' })
                                                activeElementRef.current = null
                                            }}
                                            onChange={(e) => {
                                                setReferenceForTestOfControl(e.target.value)
                                            }}
                                            onFocus={(event) => {
                                                updateMyPresence({ focusedId: `OtherRelevantAssertion-ReferenceForTestOfControl-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}`, type: 'custom' })
                                                activeElementRef.current = event.target
                                            }}
                                            disabled={disabled}
                                        />
                                    </VFRenderedFieldWrapper>
                                }
                            </TableCell>
                            <TableCell sx={{ ...cellStyles, width: '135px', ...selectStyles }}>
                                {isInternalControlEffectiveness &&
                                    <VFRenderedFieldWrapper
                                        className={styles.field__wrapper}
                                        isLockedByUser={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-ControlRiskAssessmentName-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase()))}
                                        isLocked={(others.find((user) => user.presence.focusedId === `OtherRelevantAssertion-ControlRiskAssessmentName-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())) && isExpanded}
                                    >
                                        <CLADropdown
                                            styles={{ fontSize: '14px', fontWeight: '600' }}
                                            options={controlRiskOptions?.map(c => c.controlRiskAssessmentName)
                                            }
                                            value={controlRisk.controlRiskAssessmentName ?? ''}
                                            onChange={(e) => {
                                                setIsRiskChanged(true)
                                                const control = controlRiskOptions?.find(c => c.controlRiskAssessmentName === e.target.value);
                                                if (control) {
                                                    setControlRisk({ controlRiskAssessmentId: control.controlRiskAssessmentId, controlRiskAssessmentName: control.controlRiskAssessmentName });
                                                    otherRelevantAssertion.controlRiskAssessmentId = control.controlRiskAssessmentId;
                                                    otherRelevantAssertion.controlRiskAssessmentName = control.controlRiskAssessmentName
                                                }
                                            }}
                                            onOpen={() => {
                                                updateMyPresence({ focusedId: `OtherRelevantAssertion-ControlRiskAssessmentName-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}-${JSON.stringify(otherRelevantAssertion.assertionList)}`, type: 'custom' })
                                            }}
                                            onClose={(e) => {
                                                updateMyPresence({ focusedId: null, type: 'custom' })
                                            }}
                                            onClosedByIdle={()=>{
                                                updateMyPresence({ focusedId: null, type: 'custom' })
                                            }}
                                            isDisabled={disabled}
                                            isIdle={isIdle}
                                        />
                                    </VFRenderedFieldWrapper>
                                }
                                {!isInternalControlEffectiveness && 'High'}

                            </TableCell>
                            <TableCell sx={{ ...cellStyles, width: '135px' }}>
                                {rmm}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

CLAOtherRelevantAssertions.propTypes = {
    inherentRiskOptions: PropTypes.array,
    controlRiskOptions: PropTypes.array,
    isInternalControlEffectiveness: PropTypes.bool,
    projectAuditAreaOtherRelevant: PropTypes.object,
    disabled: PropTypes.bool,
}

export default CLAOtherRelevantAssertions;