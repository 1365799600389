import { useState, useEffect } from 'react';
import { 
  Checkbox, 
  TextField, 
  Grid, 
  FormControlLabel, 
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form'; 
import { Flex } from "@ais/palette"; 
import { useRoomIdle } from '@ais/hooks';

const CheckBoxOtherOption = ({ allowOtherOption, options, id, disabled, lockField, unlockField, onSubmit, onFocus, isQuestionGroupIdle }) => {

  const { control, setValue, watch } = useFormContext();
  const [textValue, setTextValue] = useState(''); 
  const isIdle = useRoomIdle();
  const values = watch(id);
  const handleTextBlur = () => {
    if (isIdle || isQuestionGroupIdle) return
    const selectedValuesFromOptions = options?.filter(v => values?.includes(v)) ?? [];
    setValue(id, [...new Set([...selectedValuesFromOptions, textValue])])
    onSubmit(id)
    unlockField && unlockField()
  }

  const handleFocus = (e) => {
    onFocus(e, id)
    lockField && lockField()
  };

  useEffect(() => {
    if (isIdle || isQuestionGroupIdle) {
      if (values) {
        setTextValue(values?.filter(v => !options.includes(v)).join(', '))
      }
    }
  }, [isIdle, isQuestionGroupIdle])

  useEffect(() => {
    if (Array.isArray(values)) {
      setTextValue(values.filter(v => !options.includes(v)).join(', '))
  }
  },[options, values])

  if (!allowOtherOption) return <></>; 
  return ( 
    <Grid style={{ minWidth: '150px' }} item xs={1}> 
      <Flex direction="row" align="baseline"> 
        <FormControlLabel 
          label="Other" 
          control={ 
            <Checkbox 
              checked={!!textValue} 
              onChange={(e) => { 
                if (!e.target.checked) { 
                  setTextValue(''); 
                  setValue(id, values.filter(v => v !== textValue)); 
                  onSubmit(id)
                } 
              }} 
              inputProps={{
                "test-id": `Checkbox-${id}`,
                "test-value": textValue
              }}
              disabled={disabled}
            /> 
          } 
        /> 
        <Controller 
          key={id}
          control={control} 
          name={`checkbox-other-${id}`} 
          render={({ field }) => ( 
            <TextField 
              {...field} 
              fullWidth 
              value={textValue} 
              onChange={e=> setTextValue(e.target.value)} 
              onFocus={handleFocus}
              onBlur={handleTextBlur}
              inputProps={{
                "test-id": `CheckboxOther-${id}`,
                "test-value": textValue
              }}
              disabled={disabled}
            />
          )}
        />
      </Flex>
    </Grid>
  );
};


export default CheckBoxOtherOption;

